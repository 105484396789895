
export default {
    config: {
      useLocalStorage: false,
    },
    useColorSchemeMediaQuery: true,
    colors: {
        text: '#0e0e0e',
        background: '#fefefe',
        primary: '#3e3e3e',
        primaryHover: '#000',
        secondary: 'green',
        accent: 'yellow',
        highlight: 'pink',
        muted: '#808080',
        modes: {
            dark: {
                text: '#fefefe',
                background: '#2e2e2e',
                primary: '#aeaeae',
                primaryHover: '#fff'
            }
        }
    },
    sizes: {
        container: 1024
    },
    fonts: {
        body: 'Lato, Roboto, Arial',
        heading: 'Lato, Roboto, Arial',
        monospace: 'Menlo',
    },
    links: {
        icon: {
            color: 'primary',
            '&:hover': {
                color: 'primaryHover'
            }
        },
        contactMeLink: {
          color: '#f0f0f0',
          textDecoration: 'none',
          '&:hover': {
            color: 'white',
            background: '#ffffff55'
          }
        },
      headerMenu: {
        color: '#f0f0f0',
        textDecoration: 'none',
        padding: ['0.25em 0.5em 0.25em 0.5em', '0.25em 2em 0.25em 2em'],
        '&:hover': {
          color: 'white',
          background: '#ffffff55'
        }
      },
        headerMenuBig: {
            color: 'white',
            textDecoration: 'none',
            padding: ['0 0.5em 0 0.5em', '0 1em 0 1em'],
            fontSize: 20
        }
    },
    grids: {
      reversed: {
        direction: ['ltr', 'rtl']
      }
    },
    styles: {
        root: {
            fontFamily: 'body',
            minHeight: '100vh',
            backgroundColor: 'background'
        },
        h1: {
            textAlign: 'center'
        },
        a: {
            color: 'text',
            textDecoration: 'none',
            fontWeight: 'bold'
        }
    }
}
